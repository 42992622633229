<style lang="scss">
.pausenzeitung {
  [class*='col-'] {
  }
  .themen-box {
    padding: 15px;
    .bild-box {
      padding: 15px 0;
    }
    h4 {
      font-size: 120%;
      text-align: center;
      margin-bottom: 29px;
    }
  }

  .fakten-all {
    @media (max-width: 992px) {
      margin: 40px 0;
    }
    .headline {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 120%;
    }
    .fakten {
      margin-top: 10px;
      margin-bottom: 10px;
      strong {
        text-transform: uppercase;
        display: inline-block;
        margin-right: 5px;

        @media (max-width: 992px) {
          display: block;
        }
      }
      margin-bottom: 10px;
    }
  }

  .tabelle-box {
    .headline {
      margin-bottom: 20px;
    }
  }
  .btn-durchblaettern {
    text-align: center;
    display: block;
    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 3px;
      background: #eee;
      margin: 10px auto;
    }
  }

  .themen-box {
    .col-lg-6 {
      margin-bottom: 20px;
    }
    padding: 15px;
    .bild-box {
      padding: 15px 0;
      height: 300px;
      img {
        max-height: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    .headline {
      font-size: 120%;
      text-align: center;
      margin-bottom: 20px;
    }
    .content-link-modal {
      display: block;
      text-decoration: none;
      text-align: center;
      padding: 10px 15px;
      border-top: 3px solid $blue;
      max-width: 100%;
      margin: 0 auto;
      &:hover {
        opacity: 0.7;
        color: $blue;
      }
    }
  }

  .tabelle-box {
    margin-top: 10px;
    .text-align-right-small {
      @media (max-width: 992px) {
        text-align: right !important;
      }
    }
  }
}

.tab-group-container {
  .title.active {
  }
  .title-container {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  .title {
    padding: 15px 20px;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  .inner-content {
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
    padding: 15px;
    img {
      display: block;
      margin: 0 auto;
    }
    .leistung {
      &:before {
        content: '';
        display: block;
        margin: 20px 0;
        width: 150px;
        height: 5px;
        background: $futter-red;
      }
      &:after {
        content: '';
        display: block;
        margin: 20px 0;
        width: 100px;
        height: 5px;
        background: $futter-red;
      }
      strong {
        display: block;
        font-size: 140%;
        text-transform: uppercase;
      }
      span {
        display: block;
        margin-top: 5px;
      }
      .preis {
        display: block;
        background: $futter-red;
        color: #fff;
        padding: 10px 15px;
        display: inline-block;
        margin-top: 10px;
      }
    }
    .bsp {
      margin-top: 30px;

      .bsp-headline {
        position: relative;
        text-align: center;
        margin-bottom: 20px;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: #000;
          position: absolute;
          top: 50%;
        }
        span {
          display: inline-block;
          padding: 10px 15px;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 90%;
          background: #fff;
          position: relative;
          z-index: 2;
        }
      }
      a {
        display: block;

        text-align: center;
        span {
          display: block;
          padding: 10px 15px;
          background: $futter-red;
          color: #fff;
        }

        img {
          padding: 15px;
        }
        &:hover {
          opacity: 0.7;
          transform: scale(0.95);
        }
      }
    }
  }
}
</style>

<template>
  <div class="content pausenzeitung container">
    <h1>Pausenzeitung</h1>
    <p class="intro-txt">
      <strong>Mehr wissen, besser verstehen.</strong> Die Pausenzeitung ist eine nationale Monatszeitung, die sich an Kinder ab der 3. Klasse Volksschule sowie deren Lehrer richtet. Auf 24 Seiten werden das aktuelle Weltgeschehen, spannende Berufsbilder sowie die Themen Gesundheit, Ernährung, Sport und vieles mehr ins Klassenzimmer gebracht. Das Abonnement umfasst zusätzlich Arbeitsblätter und
      Videos, die auf <a href="https://www.pausenzeitung.at" target="_blank">www.pausenzeitung.at</a> abrufbar sind.
    </p>

    <div class="row margin-40-0">
      <div class="col-lg-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/junge-zielgruppe/pausenzeitung.jpg" alt="Pausenzeitung Titelblatt" class="img-fluid img-s-75 shadow img-center" />
        <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
          <a href="../../pdf/junge-zielgruppe/Pausenzeitung-November-2021.pdf" target="_blank">Neugierig? Werfen Sie einen Blick in die Ausgabe!</a>
        </div>
      </div>
      <div class="offset-lg-1"></div>
      <div class="col-lg-5 justify-content-center align-self-center fakten-all">
        <div class="row fakten">
          <div class="col-lg-12"><strong>Erscheinungstermine</strong></div>
          <div class="col-lg-12">Jänner, März, April, Mai, Juni, Oktober, November, Dezember</div>
        </div>
        <div class="row fakten">
          <div class="col-lg-12"><strong>Auflage</strong></div>
          <div class="col-lg-12">ab 10.500 Stück (Mindestauflage)</div>
        </div>
        <div class="row fakten">
          <div class="col-lg-12"><strong>Erscheinungsgebiet</strong></div>
          <div class="col-lg-12">österreichweit</div>
        </div>
        <div class="row fakten">
          <div class="col-lg-12"><strong>Vertrieb</strong></div>
          <div class="col-lg-12">Abo-Angebot für Schulen</div>
        </div>
        <div class="row fakten">
          <div class="col-lg-12"><strong>Seitenumfang</strong></div>
          <div class="col-lg-12">24 Seiten</div>
        </div>
        <div class="row fakten">
          <div class="col-lg-12"><strong>Arbeitsblätter</strong></div>
          <div class="col-lg-12">Bis zu 10 Stück pro Ausgabe</div>
        </div>
      </div>
    </div>

    <div class="margin-tb-m"></div>
    <h2>Ihre Werbemöglichkeiten</h2>

    <TabGroup>
      <Tab tabid="pause-tab1" :title="'Paket Pausenzeitung'" active>
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/junge-zielgruppe/paket-pausenzeitung.png" alt="Paket Pausenzeitung" class="img-fluid img-s-75 img-center" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h2>Pausenzeitung</h2>
            <ul class="content-ul">
              <li>1 Doppelseite in der Pausenzeitung</li>
              <li>Pädagogische Aufbereitung von Arbeitsblättern passend zum Inhalt</li>
              <li>Logopräsenz (S. 3, rechts unten) in der Pausenzeitung</li>
              <li>Optionales Video: 1.500,00 (PR oder redaktionell)</li>
            </ul>
            <br />
            <h2>Leistungen &amp; Tarif</h2>
            <div class="row">
              <div class="col-lg-12">
                <div class="tabelle-box">
                  <div class="row head-row hide-small">
                    <div class="col-lg-6 text-align-left"></div>
                    <div class="col-lg-6 text-align-right">Tarif</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Paket</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">Pausenzeitung</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">5.000,00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis text-center">Alle Preise verstehen sich in Euro exklusive 5% Werbeabgabe und 20% Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="pause-tab2" :title="'Paket Pausenzeitung + Kinderzeitung'">
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/junge-zielgruppe/paket-pausenzeitung-kinderzeitung.png" alt="Paket Pausenzeitung Kinderzeitung" class="img-fluid img-s-75 img-center" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h2>Pausenzeitung + Kinderzeitung</h2>
            <ul class="content-ul">
              <li>1 Doppelseite in der Pausenzeitung und 1 Doppelseite in der Kleinen Kinderzeitung Steiermark, Kärnten und Wien</li>
              <li>Pädagogische Aufbereitung von Arbeitsblättern passend zum Inhalt</li>
              <li>Logopräsenz (S. 3, rechts unten) in der Pausenzeitung</li>
            </ul>
            <br />
            <h2>Leistungen &amp; Tarif</h2>
            <div class="row">
              <div class="col-lg-12">
                <div class="tabelle-box">
                  <div class="row head-row hide-small">
                    <div class="col-lg-6 text-align-left"></div>
                    <div class="col-lg-6 text-align-right">Tarif</div>
                  </div>
                  <div class="row content-row">
                    <div class="col-6 text-align-left visible-small">Paket</div>
                    <div class="col-lg-6 col-6 text-align-left text-align-right-small">Pausenzeitung + Kleine Kinderzeitung</div>
                    <div class="col-6 text-align-left visible-small">Tarif</div>
                    <div class="col-lg-6 col-6 text-align-right">10.000,00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis text-center">Alle Preise verstehen sich in Euro exklusive 5% Werbeabgabe und 20% Umsatzsteuer.</div>
      </Tab>
    </TabGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Pausenzeitung',
  components: {
    TabGroup,
    Tab,
  },
});
</script>
